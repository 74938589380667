<template lang="html">
    <div :id="'fileGroup'+content.idAttachment" class="file d-flex flex-wrap justify-content-center align-items-start">
        <div class="preview d-flex justify-content-center align-items-center" @click="activeMenu($event)">
            <img @error="changeImage($event)" :src="content.pathPreview" class="cover">
            <div class="floatMenu flex-column justify-content-center align-items-center align-content-center">
                <div class="action download" @click="downloadFile()"><img v-svg-inline :src="a19"></div>
                <div class="action view" @click="viewFile()"><img v-svg-inline :src="a20"></div>
                <div class="action text nameEdit" @click="editFileName()"><img v-svg-inline :src="a28"></div>
                <div class="action trash" @click="deleteFile()"><img v-svg-inline :src="a21"></div>
            </div>
        </div>
        <div class="text d-flex flex-wrap text-start align-items-end align-content-start pt-3">
            <span v-if="fileType(content.extension) == 'pdf'" class="subtitled w-100 d-inline-flex m-0 p-0 align-items-end align-content-start justify-content-center">Reporte:</span>
            <span @blur="changeName($event)" :id="'file'+content.idAttachment" @dblclick="edit($event)" class="name w-100 text-center d-inline-flex justify-content-center align-items-end m-0 align-content-start overflow-hidden">{{String(content.fileNameUser)}}</span>
        </div>
    </div>
</template>
<script>
export default {
    name:"file",
    emits:['delete', 'edit'],
    props:{
        content:{},
        dated:'',
        folder:'',
        idx:0,
        patient:0,
    },
    data(){
        return{
            a18:require('@/assets/images/expedient/a18.png'),
            a19:require('@/assets/images/expedient/a19.svg'),
            a20:require('@/assets/images/expedient/a20.svg'),
            a21:require('@/assets/images/expedient/a21.svg'),
            a26:require('@/assets/images/expedient/a26.jpg'),
            a28:require('@/assets/images/expedient/a28.svg')
        }
    },
    methods:{
        changeName(e){
            this.filterText(e);
            e.target.setAttribute("contenteditable", false);
            document.getElementById('fileGroup'+this.content.idAttachment).classList.remove('editing');
            e.target.classList.remove('edit');
            let text = e.target.innerText;
            this.$emit('edit', this.content.idAttachment, text)
        },
        changeImage(e){
            e.target.src = this.a26;
        },
        downloadFile(){
            /*window.parent.postMessage({ code: 'download', file:{
                url:this.content.pathFile}
            }, '*');*/
            fetch(this.content.pathFile)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = this.content.folder + "-" + this.content.fileNameUser + "." + this.content.extension;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => alert(err));
        },
        viewFile(){
            let object = {
                type:this.fileType(this.content.extension), 
                params:{
                    app:'0000',
                    patient:this.patient
                }, 
                url:this.content.pathFile
            }
            if(object.type=='pdf'){
                object.params.action = "download";
                object.params.module = "imagenologia";
                object.params.patient = this.patient;
                object.params.appointment = "0"
            }
            window.parent.postMessage({ code: 'viewImage', file:object
            }, '*');
        },
        editFileName(){
            let file = document.getElementById('file'+this.content.idAttachment);
            document.getElementById('fileGroup'+this.content.idAttachment).classList.add('editing');
            file.setAttribute("contenteditable", true);
            file.classList.add("edit");
            file.focus();
        },
        deleteFile(){
            this.$emit('delete', this.content.idAttachment, this.dated, this.folder, this.idx)
        },
        filterText(e){
            let str = e.target.innerText;
            if ((str===null) || (str==='')){
                return false;
            }else{
                str = str.toString();
            }
            str = str.replace( /(<([^>]+)>)/ig, '').replace(/[^a-zA-Z 0-9.]+/g,"");
            e.target.innerText = str;
        },
        fileType(extention){
            extention = extention.toLowerCase();
            switch(extention){
                case 'png':
                case 'jpg':
                case 'jpeg':
                case 'gif':
                case 'bimap':
                    return 'image';
                    break;
                case 'pdf':
                    return 'pdf'
                    break;
                default:
                    return 'unkown'
            }
        },
        edit(e){
            e.target.setAttribute("contenteditable", true);
            document.getElementById('fileGroup'+this.content.idAttachment).classList.add('editing')
            e.target.classList.add("edit");
            e.target.focus();
        },
        activeMenu(e){
            e.target.classList.add("active");
            setTimeout(()=>{
                e.target.classList.remove("active");
            }, 5000)
        },
    }
}
</script>
<style lang="css">
    .file{
        width: 180px;
        height: 200px;
        flex: 0 0 180px;
        position: relative;

    }
    .editing.file{
        height: auto !important;
    }
    .file img{
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
    .preview{
        width: 140px;
        height: 140px;
        cursor: pointer;
    }
    .file:hover .floatMenu{
        display: flex !important;
    }
    .preview img{
        pointer-events: none;
    }
    .preview img.content{
        width: 100%;
        height: 100%;
        object-fit: content;
    }
    .preview img.cover{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border:1px solid #D5D5D5;
    }
    .text{
        width: 100%;
        height: 60px;
        overflow: hidden;
    }
    .editing .text{
        height: auto !important;
    }
    .editing .text .name{
        word-break: break-all !important;
        white-space: normal !important;
    }
    .text .name:not(.edit){
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }
    .text .subtitled{
        font-size: 15px;
        color: #343434;
        font-weight:bold;
         line-height: 1em;
    }
    .text .name{
        font-size: 12px;
        color: #343434;
        line-height: 1.2em;
        word-break: keep-all;
        white-space: nowrap;
    }
    .text .name.edit{
        padding: 10px 5px 10px 5px;
        background-color: #e5e4e4;
        outline: none;
        border:3px dotted #63F3FF;
    }
    .active .floatMenu{
        display: flex !important;
    }
    .floatMenu{
        display: none;
        position: absolute;
        top:2%;
        right: -10px;
        background-color: #eceaea ;
        border:1px solid #fff;
        border-radius: 30px;
        height: 130px;
        width: 30px;
        animation: appendMenu 0.3s linear forwards;
    }
    .floatMenu .action{
        width: 70%;
        height: 30px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        overflow: visible;
    }
    .floatMenu .action:not(:first-child){
        border-top: 1px solid #fff;
    }
    .floatMenu .action:hover {

    }
    .floatMenu .action:hover svg path{
        fill: transparent;
        stroke: #63F3FF;
        transform:scale(1.2);
        transform-origin: 50% 50%;
    }
    .floatMenu svg{
        pointer-events: none;
        overflow: visible;
    }
    .floatMenu svg path{
        stroke: rgb(253, 75, 69);
        fill: transparent;
    }
    .floatMenu .action.trash svg{
        width: 22px;
        height: 22px;
    }
    .floatMenu .action.view svg{
        width: 20px;
        height: 20px;
    }
    .floatMenu .action.text svg{
        width: 20px;
        height: 20px;
    }
    .floatMenu .action.download svg{
        width: 25px;
        height: 25px;
    }

    @keyframes appendMenu {
        0%{
            transform: translate(-100px, 0);
            opacity: 0;
        }
        70%{
            transform: translate(0, 0);
        }
        100%{
            opacity: 1;
        }
    }
</style>